var carousels = $(".owl-carousel");

carousels.each(function (index, carousel) {
    var container = $(carousel).closest(".experience-commerce_layouts-customCarousel");
    $(carousel).owlCarousel({
        loop: true,
        margin: 20,
        center: true,
        nav: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
                stagePadding: 100,
                margin: 15,
            },
            425: {
                items: 1,
                stagePadding: 120,
                margin: 15,
            },
            600: {
                items: 3,
                margin: 15,
            },
            768: {
                items: 3,
                margin: 15,
            },
            1000: {
                items: 5,
            },
            2000: {
                items: 5,
            },
        },
    });

    container.find(".custom-nav .next").on("click", function () {
        $(carousel).trigger("next.owl.carousel");
    });
    container.find(".custom-nav .prev").on("click", function () {
        $(carousel).trigger("prev.owl.carousel");
    });
});
